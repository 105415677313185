import React, { FC, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../components/Banner";
import styles from "./LandingPage.module.scss";
import { useHistory } from "react-router-dom";
import CustomModal from "../../shared/components/UIElements/CustomModal";
import Button from "../../shared/components/UIElements/Button";

const LandingPage: FC = () => {
  let history = useHistory();

  const [show, setShow] = useState(false);
  const [serviceName, setServiceName] = useState("");

  const onClickhandle = (e: any) => {
    history.push({
      pathname: "/booking",
      state: e.target.innerHTML,
    });
  };

  const [error, setError] = useState(false);

  return (
    <Container>
      <Row>
        <Col>
          <Banner />
        </Col>
      </Row>
      <Row>
        <Col className={`${styles["select-service-header"]} text-center mt-3`}>
          Select Service
        </Col>
      </Row>
      <Row
        className={`flex-column text-center mt-1 ${styles["option-styles"]}`}
      >
        <Col className="pt-2 px-0">
          <Row>
            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} ${styles["fogging"]} m-auto`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={onClickhandle}
                >
                  Fogging - Anti-Covid Disinfectant
                </div>
              </div>
            </Col>

            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} m-auto ${styles["deep-cleaning"]}`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={onClickhandle}
                >
                  Deep Cleaning
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} m-auto ${styles["end-of"]}`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={onClickhandle}
                >
                  End of Tenancy
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} m-auto ${styles["regular-restaurants"]}`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={onClickhandle}
                >
                  Regular Cleaning Restaurants / Pubs
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} m-auto ${styles["regular-offices"]}`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={onClickhandle}
                >
                  Regular Cleaning Offices
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} className="mb-3">
              <div
                className={`${styles["service-item"]} m-auto ${styles["other"]}`}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.backdrop}`}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Other
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <Container>
          <Row className="mt-1">
            <Col>Name of the service</Col>
          </Row>
          <Row>
            <Col className="text-center mt-1">
              <input
                type="text"
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setServiceName(e.target.value.trim());
                }}
                onFocus={() => {
                  setError(false);
                }}
              ></input>
              {error && (
                <div
                  style={{ color: "red", fontSize: "0.8rem" }}
                  className="text-left"
                >
                  Enter service name
                </div>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end my-3">
            <Col xs={3} className="p-0">
              <Button
                text="Ok"
                onClick={() => {
                  if (serviceName === "") {
                    setError(true);
                    return;
                  }

                  setShow(false);
                  history.push({
                    pathname: "/booking",
                    state: serviceName,
                  });
                }}
              ></Button>
            </Col>
            <Col xs={3} className="p-0 mx-2">
              <Button
                text="Cancel"
                onClick={() => {
                  setError(false);
                  setShow(false);
                }}
              ></Button>
            </Col>
          </Row>
        </Container>
      </CustomModal>
    </Container>
  );
};

export default LandingPage;
