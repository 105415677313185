import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useReactAuth} from "../Contexts/AuthContext";
import styles from "./Login.module.scss";
import {useHistory} from "react-router";

const Login = () => {
  let history = useHistory();

  const [state, setState] = useState({email: "", password: ""});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {login} = useReactAuth();

  const handleOnChange = (e: any) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await login(state.email, state.password);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  if (useReactAuth().currentUser) {
    history.push("/admin");
  }

  return (
    <Container>
      <Row>
        <Col xs={12} sm={8} md={6} className="offset-sm-2 offset-md-3  mt-5">
          <div className={`${styles["login-text"]}`}>Login</div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="email"
                name="email"
                onChange={handleOnChange}
                value={state.email}
                onFocus={() => {
                  setError(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="password"
                name="password"
                onChange={handleOnChange}
                value={state.password}
                onFocus={() => {
                  setError(false);
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Sign in
              </button>
            </div>
          </form>
        </Col>
        <Col xs={12} className="text-center">
          {error && <div style={{color: "red"}}>Login failed</div>}
          {loading && <div>Login...</div>}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
