export const TIMESLOTS = [
  { slot: "00:00 AM - 01:00 AM",startStr:"00:00 AM", endStr:"01:00 AM", id: 1, startAtHour:0, endAtHour:1, startAtMin:0, endAtMin:0 },
  { slot: "01:00 AM - 02:00 AM", startStr:"01:00 AM", endStr:"02:00 AM",id: 2, startAtHour:1, endAtHour:2,startAtMin:0, endAtMin:0 },
  { slot: "02:00 AM - 03:00 AM",startStr:"02:00 AM", endStr:"03:00 AM", id: 3, startAtHour:2, endAtHour:3,startAtMin:0, endAtMin:0 },
  { slot: "03:00 AM - 04:00 AM",startStr:"03:00 AM", endStr:"04:00 AM", id: 4, startAtHour:3, endAtHour:4,startAtMin:0, endAtMin:0 },
  { slot: "04:00 AM - 05:00 AM",startStr:"04:00 AM", endStr:"05:00 AM", id: 5, startAtHour:4, endAtHour:5,startAtMin:0, endAtMin:0 },
    { slot: "05:00 AM - 06:00 AM",startStr:"05:00 AM", endStr:"06:00 AM", id: 6, startAtHour:5, endAtHour:6,startAtMin:0, endAtMin:0 },
    { slot: "06:00 AM - 07:00 AM", startStr:"06:00 AM", endStr:"07:00 AM",id: 7, startAtHour: 6, endAtHour:7,startAtMin:0, endAtMin:0},
    { slot: "07:00 AM - 08:00 AM", startStr:"07:00 AM", endStr:"08:00 AM",id: 8, startAtHour: 7, endAtHour: 8,startAtMin:0, endAtMin:0},
    { slot: "08:00 AM - 09:00 AM",startStr:"08:00 AM", endStr:"09:00 AM", id: 9, startAtHour: 8, endAtHour:9 ,startAtMin:0, endAtMin:0},
    { slot: "09:00 AM - 10:00 AM", startStr:"09:00 AM", endStr:"10:00 AM",id: 10, startAtHour: 9, endAtHour:10 ,startAtMin:0, endAtMin:0},
    { slot: "10:00 AM - 11:00 AM",startStr:"10:00 AM", endStr:"11:00 AM", id: 11, startAtHour: 10, endAtHour: 11,startAtMin:0, endAtMin:0 },
    { slot: "11:00 AM - 12:00 PM",startStr:"11:00 AM", endStr:"12:00 PM", id: 12, startAtHour: 11, endAtHour:12,startAtMin:0, endAtMin:0},
    { slot: "12:00 PM - 01:00 PM",startStr:"12:00 PM", endStr:"01:00 PM", id: 13, startAtHour:12, endAtHour:13,startAtMin:0, endAtMin:0 },
    { slot: "01:00 PM - 02:00 PM",startStr:"01:00 PM", endStr:"02:00 PM", id: 14, startAtHour:13, endAtHour:14,startAtMin:0, endAtMin:0 },
    { slot: "02:00 PM - 03:00 PM", startStr:"02:00 PM", endStr:"03:00 PM",id: 15, startAtHour:14, endAtHour:15 ,startAtMin:0, endAtMin:0},
    { slot: "03:00 PM - 04:00 PM", startStr:"03:00 PM", endStr:"04:00 PM",id: 16, startAtHour:15, endAtHour:16 ,startAtMin:0, endAtMin:0},
    { slot: "04:00 PM - 05:00 PM",startStr:"04:00 PM", endStr:"05:00 PM", id: 17, startAtHour:16, endAtHour:17,startAtMin:0, endAtMin:0 },
      { slot: "05:00 PM - 06:00 PM",startStr:"05:00 PM", endStr:"06:00 PM", id: 18, startAtHour:17, endAtHour:18,startAtMin:0, endAtMin:0},
      { slot: "06:00 PM - 07:00 PM", startStr:"06:00 PM", endStr:"07:00 PM",id: 19, startAtHour:18, endAtHour:19,startAtMin:0, endAtMin:0},
      { slot: "07:00 PM - 08:00 PM", startStr:"07:00 PM", endStr:"08:00 PM",id: 20, startAtHour: 19, endAtHour: 20,startAtMin:0, endAtMin:0},
      { slot: "08:00 PM - 09:00 PM", startStr:"08:00 PM", endStr:"09:00 PM",id: 21, startAtHour: 20, endAtHour:21,startAtMin:0, endAtMin:0 },
      { slot: "09:00 PM - 10:00 PM", startStr:"09:00 PM", endStr:"10:00 PM",id: 22, startAtHour: 21, endAtHour:22,startAtMin:0, endAtMin:0 },
      { slot: "10:00 PM - 11:00 PM", startStr:"10:00 PM", endStr:"11:00 PM",id: 23, startAtHour: 22, endAtHour: 23,startAtMin:0, endAtMin:0 },
      { slot: "11:00 PM - 11:59 PM", startStr:"11:00 PM", endStr:"11:59 PM",id: 24, startAtHour: 23, endAtHour:23,startAtMin:0, endAtMin:59},
  ];