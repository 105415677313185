import React, { FC } from "react";
import styles from "./Button.module.scss";
import { Container, Row, Col } from "react-bootstrap";

interface IProps {
  text: string;
  onClick: Function;
  isDisabled?: boolean;
}

const Button: FC<IProps> = ({ text, onClick, isDisabled }) => {
  return (
    <Container
      className={`${styles["button-container"]} ${
        isDisabled ? `${styles.disabled}` : ``
      }`}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
    >
      <Row className={"text-center py-2"}>
        <Col className={styles["button-text"]}>{text}</Col>
      </Row>
    </Container>
  );
};

export default Button;
