import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IProps {
  message: string;
  isInfo: boolean;
}

toast.configure();

const Toast = (props: IProps) => {
  const notify = () => {
    if (props.isInfo) {
      return toast.info(props.message, {
        position: "top-left",

        closeButton: true,
        autoClose: 2000,
      });
    } else {
      return toast.error(props.message, {
        position: "top-left",
        closeButton: true,
        autoClose: 2000,
      });
    }
  };

  return <div>{notify()}</div>;
};

export default Toast;
