import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../shared/components/UIElements/Button";
import styles from "./Booking.module.scss";
import TimeSlots from "../components/TimeSlots";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import Banner from "../components/Banner";
import { useFirestore } from "reactfire";
import { Redirect, useHistory } from "react-router-dom";
import CustomModal from "../../shared/components/UIElements/CustomModal";
import AdminModalChild from "../components/AdminModalChild";
import ClientModalChild from "../components/ClientModalChild";
import "firebase/functions";
import moment from "moment";
import firebase from "firebase";
import OfflineModalChild from "../components/OfflineModalChild";

interface IProps {
  type: string;
}

interface IState {
  start: {
    startAtHour: number | null;
    startAtMin: number | null;
    id: number | null;
    startStr: string | null;
  };
  end: {
    endAtHour: number | null;
    endAtMin: number | null;
    id: number | null;
    endStr: string | null;
  };
}

interface Slot {
  id: string;
  startAt: Date;
  endAt: Date;
  disabledIdRange: number[];
}

const Booking: FC<IProps> = ({ type }) => {
  const store = useFirestore();
  let history = useHistory();

  const [disabledSlotsState, setDisabledSlotsState] = useState<Slot[]>([]);
  const [serviceName] = useState(history.location.state);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [error, setError] = useState("");
  //This state is used to set the disabled time slots of the selected date
  const [
    disabledTimeSlotsoFCurrentDate,
    setDisabledTimeSlotsoFCurrentDat,
  ] = useState<any[] | null>(null);

  useEffect(() => {
    store
      .collection("disabledTimeSlots")
      .onSnapshot((qSnapshot) => {
        if (qSnapshot.size > 0) {
          qSnapshot.docChanges().forEach((change) => {
            const slot = change.doc.data();

            if (change.type === "added") {
              setDisabledSlotsState((prevState) => {
                const temp = prevState || [];

                temp.push({
                  id: change.doc.id,
                  startAt: (slot.startAt as firebase.firestore.Timestamp).toDate(),
                  endAt: (slot.endAt as firebase.firestore.Timestamp).toDate(),
                  disabledIdRange: slot.disabledIdRange,
                });

                return Object.assign([], temp);
              });
            } else if (change.type === "modified") {
              setDisabledSlotsState((prevState) => {
                const temp = prevState || [];

                temp
                  .filter((i) => i.id === change.doc.id)
                  .forEach((i) => (i.disabledIdRange = slot.disabledIdRange));

                return Object.assign([], temp);
              });
            }
          });
        } else {
          setDisabledSlotsState([]);
        }
      });
  }, [store]);

  useEffect(() => {
    let array: any = [];

    if (disabledSlotsState === undefined || disabledSlotsState.length === 0) {
      setDisabledTimeSlotsoFCurrentDat([]);
    } else {
      disabledSlotsState.forEach((element) => {
        if (
          element.startAt.getDate() === selectedDate?.getDate() &&
          element.startAt.getMonth() === selectedDate?.getMonth() &&
          element.startAt.getFullYear() === selectedDate?.getFullYear()
        ) {
          array.push(element);
        }
      });

      setDisabledTimeSlotsoFCurrentDat(array);
    }
  }, [disabledSlotsState, selectedDate]);

  const [showUserModal, setShowUserModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showOfflineModal, setShowOfflineModal] = useState(false);

  const handleUserModalClose = () => setShowUserModal(false);
  const handleUserModalShow = () => setShowUserModal(true);

  const handleAdminModalClose = () => setShowAdminModal(false);
  const handleAdminModalShow = () => setShowAdminModal(true);

  const handleOfflineModalClose = () => setShowOfflineModal(false);
  const handleOfflineModalShow = () => setShowOfflineModal(true);

  const handleDayClick = (day) => {
    if (
      day <
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ).setHours(0, 0, 0)
      )
    ) {
      return;
    }

    // This segement sets all the disabled slots of the to be selected date
    let array: any = [];
    disabledSlotsState.forEach((element) => {
      if (
        element.startAt.getDate() === day.getDate() &&
        element.startAt.getMonth() === day.getMonth() &&
        element.startAt.getFullYear() === day.getFullYear()
      ) {
        array.push(element);
      }
      setDisabledTimeSlotsoFCurrentDat(array);
    });

    setSelectedDate(day);
    setError("");
  };

  const [timeSlot, setTimeSlot] = useState<IState>({
    start: { startAtHour: null, startAtMin: null, id: null, startStr: null },
    end: { endAtHour: null, endAtMin: null, id: null, endStr: null },
  });

  const [disabledTimeSlotsState, setDisabledTimeSlotsState] = useState<
    number[] | null
  >(null);

  // this function returns all the previous dates in the current month
  // this function is used in day picker widget to display diabled dates
  const pastDays = () => {
    let disabledDaysArray: Date[] = [];

    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();

    for (let i = 1; i < day; i++) {
      disabledDaysArray.push(new Date(year, month, i));
    }

    return disabledDaysArray;
  };

  const modifiers = {
    selectedDay: selectedDate,
  };
  const modifiersStyles = {
    selectedDay: {
      color: "white",
      backgroundColor: "#3376d0",
    },
  };

  return (
    <Container>
      {!serviceName && type === "user" && <Redirect to="/" />}
      <Banner />
      <Row className="mt-2 mx-0">
        <Col className={styles["time-slot-title"]}>
          {type === "user"
            ? "Select Your Time Slots"
            : "Select Slots To Disable"}
        </Col>
      </Row>
      <hr />
      <Row className={`mt-4 ${styles["bottom-height-booking"]} px-0`}>
        <Col xs={12} sm={5} md={4} className="text-center">
          <DayPicker
            className={`${styles["date-picker-width"]}`}
            onDayClick={handleDayClick}
            selectedDays={selectedDate}
            disabledDays={pastDays()}
            fromMonth={new Date()}
            onMonthChange={(month) => {
              //any date less than current date
              setSelectedDate(new Date(1971, 2, 3));
              setDisabledTimeSlotsoFCurrentDat([]);
            }}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          md={3}
          className={`m-0 ${styles["time-slot-container"]} ml-lg-0 ml-sm-auto px-0 pl-sm-3`}
        >
          <TimeSlots
            disabledTimeSlotsState={disabledTimeSlotsState}
            setDisabledTimeSlotsState={setDisabledTimeSlotsState}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            disabledTimeSlotsoFCurrentDate={disabledTimeSlotsoFCurrentDate}
            selectedDate={selectedDate}
            setError={setError}
            type={type}
          />
        </Col>

        <Col
          md={4}
          sm={12}
          xs={12}
          className={`d-flex flex-column text-right ${
            timeSlot.end.id && timeSlot.start.id
              ? `justify-content-between`
              : `justify-content-end`
          } pr-sm-0 pr-2 pl-2 offset-md-1`}
        >
          {timeSlot.end.id && timeSlot.start.id ? (
            <Row style={{ fontSize: "1rem" }} className="m-0">
              <Col
                xs={12}
                className="p-0"
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              >
                <div
                  style={{
                    width: "180px",
                    textAlign: "left",
                  }}
                  className="mr-auto ml-md-auto mr-md-0"
                >
                  {type === "user"
                    ? "Book from"
                    : type === "admin"
                    ? "Disable from"
                    : ""}
                </div>
              </Col>
              <Col xs={12} className="p-0">
                <div
                  style={{
                    backgroundColor: "#017979",
                    width: "180px",
                    textAlign: "center",
                  }}
                  className="px-1 text-light mr-auto ml-md-auto mr-md-0"
                >
                  {moment(selectedDate).format("DD-MM-YYYY") +
                    " " +
                    timeSlot.start.startStr}
                </div>
              </Col>
              <Col
                xs={12}
                className="mt-2 p-0"
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              >
                <div
                  style={{
                    width: "180px",
                    textAlign: "left",
                  }}
                  className="mr-auto ml-md-auto mr-md-0"
                >
                  To
                </div>
              </Col>
              <Col xs={12} className="p-0">
                <div
                  style={{
                    backgroundColor: "#017979",
                    width: "180px",
                    textAlign: "center",
                  }}
                  className="px-1 text-light mr-auto ml-md-auto mr-md-0"
                >
                  {moment(selectedDate).format("DD-MM-YYYY") +
                    " " +
                    timeSlot.end.endStr}
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row className="mb-2 mt-2 mb-sm-2 mb-md-2 mx-0">
            <Col className="p-0">
              {error && (
                <div
                  style={{ color: "red", fontWeight: 600, textAlign: "center" }}
                >
                  {error}
                </div>
              )}
              <Button
                text={type === "user" ? "Book now" : "Save slots"}
                onClick={() => {
                  const condition = navigator.onLine ? "online" : "offline";
                  if (condition === "online") {
                    fetch("https://www.google.com/", {
                      // Check for internet connectivity
                      mode: "no-cors",
                    })
                      .then(() => {
                        if (type === "user") {
                          if (selectedDate.getFullYear() === 1971) {
                            setError("Please select a date");
                          } else if (!timeSlot.start.id) {
                            setError("Please select a time slot");
                          } else {
                            handleUserModalShow();
                          }
                        } else {
                          if (selectedDate.getFullYear() === 1971) {
                            setError("Please select a date");
                          } else if (disabledTimeSlotsState === null) {
                            setError("Please select a time slot");
                          } else {
                            handleAdminModalShow();
                          }
                        }
                      })
                      .catch(() => {
                        handleOfflineModalShow();
                      });
                  } else {
                    handleOfflineModalShow();
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomModal show={showUserModal} handleClose={handleUserModalClose}>
        <ClientModalChild
          message="Confirm your booking."
          date={selectedDate}
          endTime={{
            endAtHour: timeSlot?.end.endAtHour,
            endAtMin: timeSlot?.end.endAtMin,
            id: timeSlot?.end.id,
            endStr: timeSlot?.end.endStr,
          }}
          onHandleClose={handleUserModalClose}
          serviceName={typeof serviceName === "string" ? serviceName : ""}
          startTime={{
            startAtHour: timeSlot?.start.startAtHour,
            startAtMin: timeSlot?.start.startAtMin,
            id: timeSlot?.start.id,
            startStr: timeSlot?.start.startStr,
          }}
        />
      </CustomModal>

      <CustomModal show={showAdminModal} handleClose={handleAdminModalClose}>
        <AdminModalChild
          message="Confirm operation"
          disabledTimeSlotsState={disabledTimeSlotsState}
          onHandleClose={handleAdminModalClose}
          date={selectedDate}
        />
      </CustomModal>
      <CustomModal
        show={showOfflineModal}
        handleClose={handleOfflineModalClose}
      >
        <OfflineModalChild handleClose={handleOfflineModalClose} />
      </CustomModal>
    </Container>
  );
};

export default Booking;
