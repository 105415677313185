import React, {createContext, useContext, useState, useEffect} from "react";
import {useAuth} from "reactfire";
import "firebase/auth";

const AuthContext = createContext<any>(null);

export function useReactAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({children}) {
  const auth = useAuth();

  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const value = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
