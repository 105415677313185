export class Validations{

    public static isValidEmail(email: string = ""): boolean {
        let isValid: boolean = true;
    
        const regexp: RegExp = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    
        if (!regexp.test(email)) {
          isValid = false;
        }
    
        if (email.trim().length < 1) {
          isValid = false;
        }
    
        return isValid;
      }

      public static isValidPhoneNumber(phoneNo: string = ""): boolean {
        let isValid: boolean = true;
    
        if (phoneNo.length < 10) {
          isValid = false;
        }
    
        return isValid;
      }

      public static isValidName(name: string = ""): boolean {
        let isValid: boolean = true;
    
        if (name.trim().length < 1) {
          isValid = false;
        }
    
        return isValid;
      }
}
