import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../shared/components/UIElements/Button";
import styles from "./Feedback.module.scss";
import { Redirect, useHistory } from "react-router-dom";
import Banner from "../components/Banner";

const Feedback: FC = () => {
  let history = useHistory();
  return (
    <Container>
      {!history.location.state && <Redirect to="/" />}
      <Banner />
      <Row className={`mt-4 ${styles["feedback-height"]}`}>
        <Col
          xs={12}
          sm={6}
          className={`${styles["thanks-text"]} d-flex flex-column justify-content-center`}
        >
          <div className={`text-center`}>Thank You!</div>
          <div className={`text-center`} style={{ fontSize: "0.9rem" }}>
            Your booking is confirmed. Our team will get in touch with you to
            discuss the next steps.
          </div>
        </Col>
        <Col
          xs={12}
          sm={4}
          md={3}
          className="text-center offset-sm-2 d-flex flex-column justify-content-center"
        >
          <Button
            text="Book again"
            onClick={() => {
              history.push("/");
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Feedback;
