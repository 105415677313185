import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../Auth/Login";
import Booking from "../Booking/pages/Booking";
import LandingPage from "../Booking/pages/LandingPage";
import Preview from "../Booking/pages/Preview";
import { useReactAuth } from "../Contexts/AuthContext";
import Feedback from "../Booking/pages/Feedback";

const Paths = () => {
  const isAuth = useReactAuth()?.currentUser;

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/booking">
            <Booking type="user" />
          </Route>
          <Route path="/feedback">
            <Feedback />
          </Route>
          <Route
            path="/admin/booking"
            component={
              isAuth
                ? () => <Booking type="admin" />
                : () => <Redirect to="/login" />
            }
          />

          <Route
            path="/admin"
            component={
              isAuth ? () => <Preview /> : () => <Redirect to="/login" />
            }
          />
          <Route path="/login">
            <Login />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
};

export default Paths;
