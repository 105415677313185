import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import Button from "../../shared/components/UIElements/Button";
import styles from "./Preview.module.scss";
import { useHistory } from "react-router-dom";
import Banner from "../components/Banner";
import { useFirestore } from "reactfire";
import "firebase/firestore";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { constructSlot } from "../../Utils/utils";
import firebase from "firebase";

interface IState {
  service: string;
  name: string;
  createdAt: Date;
  email: string;
  endAt: Date;
  startAt: Date;
  tel: string;
}

const Preview: FC = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
    })
  );

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  let history = useHistory();
  const store = useFirestore();

  const [upComingEvents, setUpComingEvents] = useState<IState[]>([]);

  const isDateInTheFuture = (date: Date): boolean => {
    if (date.getFullYear() > new Date().getFullYear()) {
      return true;
    } else if (date.getFullYear() < new Date().getFullYear()) {
      return false;
    } else if (date.getMonth() > new Date().getMonth()) {
      return true;
    } else if (date.getMonth() < new Date().getMonth()) {
      return false;
    } else if (date.getDate() > new Date().getDate()) {
      return true;
    } else if (date.getDate() < new Date().getDate()) {
      return false;
    } else return date.getHours() >= new Date().getHours();
  };

  useEffect(() => {
    setOpen(true);

    store
      .collection("bookings")
      .orderBy("startAt", "asc")
      .onSnapshot((qSnapshot) => {
        setOpen(false);

        if (qSnapshot.size > 0) {
          qSnapshot
            .docChanges()
            .filter((change) =>
              isDateInTheFuture(
                (change.doc.data()
                  .startAt as firebase.firestore.Timestamp).toDate()
              )
            )
            .forEach((change) => {
              setUpComingEvents((prevState) => {
                prevState.push({
                  service: change.doc.data().service,
                  email: change.doc.data().email,
                  name: change.doc.data().name,
                  createdAt: (change.doc.data()
                    .createdAt as firebase.firestore.Timestamp).toDate(),
                  endAt: (change.doc.data()
                    .endAt as firebase.firestore.Timestamp).toDate(),
                  startAt: (change.doc.data()
                    .startAt as firebase.firestore.Timestamp).toDate(),
                  tel: change.doc.data().tel,
                });

                return Object.assign([], prevState);
              });
            });
        }
      });
  }, [store]);

  return (
    <Container>
      <Banner />
      {/* <Row className={`mt-2 ${styles["time-slot-text"]} pr-1`}>
        <Col xs={12} className="text-center">
          Disable time slots
        </Col>
        <Col xs={12} className={"d-flex justify-content-end pr-0"}>
          <Row className="m-0">
            <Col xs={12} className="mb-1 p-0">
              <Button
                text="Select slots"
                onClick={() => {
                  history.push("/admin/booking");
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row> */}
      {upComingEvents && upComingEvents.length > 0 ? (
        <>
          <Row className="mt-2">
            <Col className={styles["upcoming-ents-text"]}>
              Upcoming Cleaning Services
            </Col>
          </Row>
          <Row>
            <Col
              className={"p-0 mt-1"}
              style={{ height: "300px", overflow: "auto" }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Tel no</th>
                    <th>Service</th>
                    <th>Appointment date</th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  {upComingEvents?.map(
                    ({ name, email, tel, startAt, endAt, service }, index) => (
                      <tr key={index}>
                        <td>{name}</td>
                        <td>{email}</td>
                        <td>{tel}</td>
                        <td>{service}</td>
                        <td>
                          {moment(new Date(startAt.toUTCString())).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          {constructSlot(new Date(startAt.toUTCString()))}
                        </td>
                        <td>{constructSlot(new Date(endAt.toUTCString()))}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mt-2">
          <Col className={styles["upcoming-ents-text"]}>
            There Are No Upcoming Cleaning Services Yet!
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col className="offset-3 offset-sm-4" xs={6} sm={4}>
          <Button
            text="Disable slots"
            onClick={() => {
              history.push("/admin/booking");
            }}
          />
        </Col>
      </Row>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Preview;
