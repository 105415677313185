import React, { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./TimeSlots.module.scss";
import { arrayUnique } from "../../Utils/utils";
import { TIMESLOTS } from "../../Constants/consts";
import {
  Backdrop,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

interface IState {
  start: {
    startAtHour: number | null;
    startAtMin: number | null;
    id: number | null;
    startStr: string | null;
  };
  end: {
    endAtHour: number | null;
    endAtMin: number | null;
    id: number | null;
    endStr: string | null;
  };
}

interface IProps {
  disabledTimeSlotsState: number[] | null;
  setDisabledTimeSlotsState: (value: number[] | null) => void;
  timeSlot: IState;
  setTimeSlot: (value: IState) => void;
  disabledTimeSlotsoFCurrentDate: any[] | null;
  selectedDate: Date;
  setError: (value: string) => void;
  type: string;
}

const TimeSlots: FC<IProps> = ({
  disabledTimeSlotsState,
  setDisabledTimeSlotsState,
  timeSlot,
  setTimeSlot,
  disabledTimeSlotsoFCurrentDate,
  selectedDate,
  setError,
  type,
}) => {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
    })
  );

  const classes = useStyles();

  const doHighligted = (id: number) => {
    if (timeSlot?.start?.id && timeSlot?.end?.id) {
      return timeSlot?.start?.id <= id && timeSlot?.end?.id >= id;
    }
  };

  const [disabledIds, setDisabledIds] = useState<number[]>([]);
  const [generateSlotArray, setGeneratedSlotArray] = useState<any[]>([]);

  useEffect(() => {
    if (type === "user") {
      let disabledIdsArray: any[] = [];

      if (disabledTimeSlotsoFCurrentDate === null) {
        setOpen(true);
        return;
      } else {
        disabledTimeSlotsoFCurrentDate.forEach(({ disabledIdRange }) => {
          disabledIdsArray = arrayUnique(
            disabledIdsArray.concat(disabledIdRange)
          );
        });
      }

      if (
        new Date().getDate() === selectedDate.getDate() &&
        new Date().getMonth() === selectedDate.getMonth() &&
        new Date().getFullYear() === selectedDate.getFullYear()
      ) {
        setGeneratedSlotArray(
          TIMESLOTS.filter((slot) => {
            return slot.startAtHour >= new Date().getHours();
          })
        );
      } else {
        setGeneratedSlotArray(TIMESLOTS);
      }

      setDisabledIds(disabledIdsArray);
      setOpen(false);

      return () => {
        setDisabledIds((ps) => []);
        setTimeSlot({
          start: {
            startAtHour: null,
            startAtMin: null,
            id: null,
            startStr: null,
          },
          end: { endAtHour: null, endAtMin: null, id: null, endStr: null },
        });
      };
    } else {
      let disabledIdsArray: any[] = [];

      if (disabledTimeSlotsoFCurrentDate === null) {
        setOpen(true);
        return;
      } else {
        disabledTimeSlotsoFCurrentDate.forEach(({ disabledIdRange }) => {
          disabledIdsArray = arrayUnique(
            disabledIdsArray.concat(disabledIdRange)
          );
        });
        setDisabledTimeSlotsState(disabledIdsArray);
      }

      setGeneratedSlotArray(TIMESLOTS);

      setOpen(false);
    }
  }, [
    disabledTimeSlotsoFCurrentDate,
    setTimeSlot,
    selectedDate,
    type,
    setDisabledTimeSlotsState,
  ]);

  const checkInDisableRange = (incomingId: number, startId: number) => {
    for (let i = startId; i < incomingId; i++) {
      if (disabledIds.includes(i)) {
        return true;
      }
    }
  };

  return type === "user" ? (
    <Container className="p-0 m-0">
      <Row className="p-0 m-0 justify-content-between">
        {generateSlotArray.map((item, index) => {
          return (
            <Col
              key={index}
              xs={3}
              sm={12}
              className={`${
                disabledIds?.includes(item.id) ? `${styles.disabled}` : ""
              } mt-2 ${
                index === 0 ? "mt-sm-0" : ""
              } text-center py-2 mx-2 mx-sm-0 ${
                styles["time-slot-styles"]
              } p-0 ${doHighligted(item.id) ? `${styles["highlighted"]}` : ""}`}
              onClick={() => {
                setError("");

                if (disabledIds?.includes(item.id)) {
                  return;
                }

                //returns if the same time slot is selected
                if (
                  timeSlot.start.id === item.id &&
                  timeSlot.end.id === item.id
                ) {
                  return;
                }
                //initial selection
                if (!timeSlot.start.id && !timeSlot.end.id) {
                  setTimeSlot({
                    start: {
                      startAtHour: item.startAtHour,
                      startAtMin: item.startAtMin,
                      id: item.id,
                      startStr: item.startStr,
                    },
                    end: {
                      endAtHour: item.endAtHour,
                      endAtMin: item.endAtMin,
                      id: item.id,
                      endStr: item.endStr,
                    },
                  });
                } else if (
                  timeSlot.start.id &&
                  timeSlot.end.id &&
                  timeSlot.end.id >= item.id
                ) {
                  setTimeSlot({
                    start: {
                      startAtHour: item.startAtHour,
                      startAtMin: item.startAtMin,
                      id: item.id,
                      startStr: item.startStr,
                    },
                    end: {
                      endAtHour: item.endAtHour,
                      endAtMin: item.endAtMin,
                      id: item.id,
                      endStr: item.endStr,
                    },
                  });
                } else if (
                  timeSlot.start.id &&
                  timeSlot.end.id &&
                  timeSlot.start.id < item.id &&
                  checkInDisableRange(item.id, timeSlot.start.id)
                ) {
                  setTimeSlot({
                    start: {
                      startAtHour: item.startAtHour,
                      startAtMin: item.startAtMin,
                      id: item.id,
                      startStr: item.startStr,
                    },
                    end: {
                      endAtHour: item.endAtHour,
                      endAtMin: item.endAtMin,
                      id: item.id,
                      endStr: item.endStr,
                    },
                  });
                } else if (
                  timeSlot.start.id &&
                  timeSlot.end.id &&
                  timeSlot.start.id < item.id
                ) {
                  setTimeSlot({
                    start: {
                      startAtHour: timeSlot.start.startAtHour,
                      startAtMin: timeSlot.start.startAtMin,
                      id: timeSlot.start.id,
                      startStr: timeSlot.start.startStr,
                    },
                    end: {
                      endAtHour: item.endAtHour,
                      endAtMin: item.endAtMin,
                      id: item.id,
                      endStr: item.endStr,
                    },
                  });
                }
              }}
            >
              {item.slot}
            </Col>
          );
        })}
      </Row>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  ) : (
    <Container className="p-0 m-0">
      <Row className="p-0 m-0 justify-content-between">
        {generateSlotArray.map((item, index) => {
          return (
            <Col
              key={index}
              xs={3}
              sm={12}
              className={`${
                disabledTimeSlotsState?.includes(item.id)
                  ? `${styles["disabled-admin"]}`
                  : ""
              } mt-2 ${
                index === 0 ? "mt-sm-0" : ""
              } text-center py-2 mx-2 mx-sm-0 ${
                styles["time-slot-styles"]
              } p-0`}
              onClick={() => {
                setError("");

                if (disabledTimeSlotsState?.includes(item.id)) {
                  setDisabledTimeSlotsState(
                    disabledTimeSlotsState.filter((id) => id !== item.id)
                  );
                } else if (disabledTimeSlotsState) {
                  setDisabledTimeSlotsState([
                    ...disabledTimeSlotsState,
                    item.id,
                  ]);
                }
              }}
            >
              {item.slot}
            </Col>
          );
        })}
      </Row>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default TimeSlots;
