import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { SuspenseWithPerf } from "reactfire";
import Paths from "./routes/routes";
import { AuthProvider } from "./Contexts/AuthContext";

function App() {
  return (
    <SuspenseWithPerf
      fallback={() => <div>Loading...</div>}
      traceId="load-application"
    >
      <AuthProvider>
        <Paths />
      </AuthProvider>
    </SuspenseWithPerf>
  );
}

export default App;
