import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import * as firebase from "firebase";
import { FirebaseAppProvider } from "reactfire";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJu8-UFVEM6tx-CIRa0bM_SmIzkklw9FI",
  authDomain: "emp-cleaning-booking-3ac75.firebaseapp.com",
  projectId: "emp-cleaning-booking-3ac75",
  storageBucket: "emp-cleaning-booking-3ac75.appspot.com",
  messagingSenderId: "36052950270",
  appId: "1:36052950270:web:ea80d602b0e6f45c02d9af",
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
