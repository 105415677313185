import React, { FC, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useFunctions } from "reactfire";
import LinearIndeterminate from "../../shared/components/Loader/Loader";
import Toast from "../../shared/components/Toast/Toast";
import Button from "../../shared/components/UIElements/Button";
import styles from "./AdminModalChild.module.scss";

interface IProps {
  message: string;
  disabledTimeSlotsState: number[] | null;
  date: Date;
  onHandleClose: Function;
}

const AdminModalChild: FC<IProps> = ({
  message,
  disabledTimeSlotsState,
  date,
  onHandleClose,
}) => {
  const setDisableTimeSlotFunction = useFunctions().httpsCallable(
    "setDisabledTimeSlots"
  );

  const [loading, setLoading] = useState(false);

  return (
    <Container>
      {loading ? (
        <Row>
          <Col className="mt-2">
            <LinearIndeterminate />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col xs={12} className="text-center pt-2">
          <span className={`${styles["modal-heading"]}`}>{message}</span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end my-3">
        <Col xs={3} className="p-0">
          <Button
            text="Save"
            isDisabled={loading}
            onClick={() => {
              setLoading(true);

              setDisableTimeSlotFunction({
                startAt: new Date(
                  date?.getFullYear() || 0,
                  date?.getMonth() || 0,
                  date?.getDate() || 0,
                  0,
                  0,
                  0
                ).toUTCString(),
                endAt: new Date(
                  date?.getFullYear() || 0,
                  date?.getMonth() || 0,
                  date?.getDate() || 0,
                  0,
                  0,
                  0
                ).toUTCString(),
                disabledIdRange: disabledTimeSlotsState,
              })
                .then(() => {
                  setLoading(false);
                  Toast({
                    message: "Disabling time slots successful",
                    isInfo: true,
                  });
                  onHandleClose();
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err.message);
                  Toast({
                    message: "Disabling time slots failed",
                    isInfo: false,
                  });
                  onHandleClose();
                });
            }}
          />
        </Col>
        <Col xs={3} className="p-0 mx-2">
          <Button
            text="Cancel"
            onClick={() => {
              onHandleClose();
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminModalChild;
