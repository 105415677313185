import moment from "moment";
import React, { useState, FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFunctions } from "reactfire";
import LinearIndeterminate from "../../shared/components/Loader/Loader";
import Toast from "../../shared/components/Toast/Toast";
import Button from "../../shared/components/UIElements/Button";
import { Validations } from "../../Validations/Validations";
import styles from "./ClientModalChild.module.scss";

interface IProps {
  message: string;
  startTime: {
    startAtHour: number | null;
    startAtMin: number | null;
    id: number | null;
    startStr: string | null;
  };
  endTime: {
    endAtHour: number | null;
    endAtMin: number | null;
    id: number | null;
    endStr: string | null;
  };
  serviceName: string;
  onHandleClose: Function;
  date: Date;
}

const ClientModalChild: FC<IProps> = ({
  message,
  startTime,
  endTime,
  serviceName,
  onHandleClose,
  date,
}) => {
  const setBookingFunction = useFunctions().httpsCallable("setBooking");

  let history = useHistory();

  const [state, setState] = useState({
    name: "",
    email: "",
    tel: "",
    loading: false,
  });
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidTel, setInvalidTel] = useState(false);

  const onHandleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      {state.loading ? (
        <Row>
          <Col className="mt-2">
            <LinearIndeterminate />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col xs={12} className="text-center pt-2">
          <span className={`${styles["modal-heading"]}`}>{message}</span>
        </Col>
      </Row>
      <Row>
        <Col
          xs={4}
          sm={3}
          className={`${styles["book-from-to"]} mt-2 pr-0`}
          style={{ fontWeight: 600 }}
        >
          Book from
        </Col>
        <Col xs={8} sm={7} className={`${styles["book-from-to"]} mt-2`}>
          {moment(date).format("DD-MM-YYYY") + " " + startTime.startStr}
        </Col>
        <Col
          xs={4}
          sm={3}
          className={`${styles["book-from-to"]} mt-2 pr-0`}
          style={{ fontWeight: 600 }}
        >
          To
        </Col>
        <Col xs={8} sm={7} className={`${styles["book-from-to"]} mt-2`}>
          {moment(date).format("DD-MM-YYYY") + " " + endTime.endStr}
        </Col>
      </Row>
      <Row className="pt-3">
        <Col
          xs={4}
          sm={3}
          className={`${styles["book-from-to"]} mt-2 pr-0`}
          style={{ fontWeight: 600 }}
        >
          Service
        </Col>
        <Col xs={8} sm={7} className={`${styles["book-from-to"]} mt-2`}>
          {serviceName}
        </Col>
        <Col
          xs={12}
          sm={3}
          className={`${styles["book-from-to"]} mt-2`}
          style={{ fontWeight: 600 }}
        >
          Name
        </Col>
        <Col xs={12} sm={7} className="mt-2">
          <input
            name="name"
            onChange={onHandleChange}
            onFocus={() => {
              setInvalidName(false);
            }}
          />
          {invalidName && (
            <div style={{ color: "red", fontSize: "0.8rem", fontWeight: 600 }}>
              Name cannot be empty
            </div>
          )}
        </Col>
        <Col
          xs={12}
          sm={3}
          className={`${styles["book-from-to"]} mt-2 pr-0`}
          style={{ fontWeight: 600 }}
        >
          Email
        </Col>
        <Col xs={12} sm={7} className="mt-2">
          <input
            type="email"
            name="email"
            onChange={onHandleChange}
            onFocus={() => {
              setInvalidEmail(false);
            }}
          />
          {invalidEmail && (
            <div style={{ color: "red", fontSize: "0.8rem", fontWeight: 600 }}>
              Invalid email
            </div>
          )}
        </Col>
        <Col
          xs={12}
          sm={3}
          className={`${styles["book-from-to"]} mt-2 pr-0`}
          style={{ fontWeight: 600 }}
        >
          Tel
        </Col>
        <Col xs={12} sm={7} className="mt-2">
          <input
            name="tel"
            onChange={onHandleChange}
            onFocus={() => {
              setInvalidTel(false);
            }}
          />
          {invalidTel && (
            <div style={{ color: "red", fontSize: "0.8rem", fontWeight: 600 }}>
              Invalid telephone number
            </div>
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-end my-3">
        <Col xs={3} className="p-0">
          <Button
            text="Book"
            isDisabled={state.loading}
            onClick={() => {
              const isValidName = Validations.isValidName(state.name);
              const isValidEmail =
                state.email === undefined ||
                state.email === "" ||
                Validations.isValidEmail(state.email);
              const isValidPhoneNumber = Validations.isValidPhoneNumber(
                state.tel
              );

              if (!isValidName) {
                setInvalidName(true);
              }

              if (!isValidEmail) {
                setInvalidEmail(true);
              }

              if (!isValidPhoneNumber) {
                setInvalidTel(true);
              }

              if (isValidEmail && isValidEmail && isValidPhoneNumber) {
                setState({ ...state, loading: true });

                setBookingFunction({
                  service: serviceName?.trim(),
                  name: state.name?.trim(),
                  email: state.email?.trim(),
                  tel: state.tel?.trim(),
                  startAt: new Date(
                    date?.getFullYear() || 0,
                    date?.getMonth() || 0,
                    date?.getDate() || 0,
                    startTime?.startAtHour || 0,
                    startTime?.startAtMin || 0,
                    0
                  ).toUTCString(),
                  endAt: new Date(
                    date?.getFullYear() || 0,
                    date?.getMonth() || 0,
                    date?.getDate() || 0,
                    endTime?.endAtHour || 0,
                    endTime?.endAtMin || 0,
                    0
                  ).toUTCString(),
                  startAtForEmail: new Date(
                    date?.getFullYear() || 0,
                    date?.getMonth() || 0,
                    date?.getDate() || 0,
                    startTime?.startAtHour || 0,
                    startTime?.startAtMin || 0,
                    0
                  ).toLocaleString("en-GB"),
                  endAtForEmail: new Date(
                    date?.getFullYear() || 0,
                    date?.getMonth() || 0,
                    date?.getDate() || 0,
                    endTime?.endAtHour || 0,
                    endTime?.endAtMin || 0,
                    0
                  ).toLocaleString("en-GB"),
                })
                  .then(() => {
                    onHandleClose();
                    setState({ ...state, loading: false });
                    history.push({
                      pathname: "/feedback",
                      state: "successful",
                    });
                  })
                  .catch((err) => {
                    onHandleClose();
                    setState({ ...state, loading: false });
                    console.log(err.message);
                    Toast({
                      message: "Booking failed",
                      isInfo: false,
                    });
                  });
              }
            }}
          />
        </Col>
        <Col xs={3} className="p-0 mx-2">
          <Button
            text="Cancel"
            onClick={() => {
              setInvalidEmail(false);
              setInvalidName(false);
              setInvalidTel(false);
              onHandleClose();
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ClientModalChild;
