import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./Banner.module.scss";
import logo from "./../../images/emplogox307xOrange.png";
import { useReactAuth } from "../../Contexts/AuthContext";
import { useHistory } from "react-router-dom";

const Banner = () => {
  let history = useHistory();

  const { logout, currentUser } = useReactAuth();

  async function handleLogout() {
    try {
      await logout();
      history.push("/");
    } catch {
      console.log("failed to logout");
    }
  }

  return (
    <Row className="mt-2">
      <Col className={`${styles.bgcontainer} px-0 position-relative`}>
        <Row className={`${styles.bgdrop} m-0`}>
          <Col md={12} className={`text-center`}>
            <img src={logo} height={105} alt="logo" />
            <h1
              className={`px-0 ${styles.emptext} d-flex align-items-center justify-content-center text-center`}
            >
              Book a Commercial Cleaning Session Now.
            </h1>
          </Col>

          {currentUser && (
            <button
              className="position-absolute btn btn-warning btn-sm"
              style={{ top: "2px", right: "2px" }}
              onClick={handleLogout}
            >
              Logout
            </button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Banner;
