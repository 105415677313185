import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../shared/components/UIElements/Button";

interface IProp {
  handleClose: () => void;
}

const OfflineModalChild: FC<IProp> = ({ handleClose }) => {
  return (
    <Container className="py-2">
      <Row>
        <Col
          className="text-center"
          style={{ fontSize: "1.2rem", fontWeight: 600 }}
        >
          It seems you are offline
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col xs={3}>
          <Button onClick={handleClose} text="Ok"></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default OfflineModalChild;
