import React, { FC } from "react";
import { Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  handleClose: () => void;
}

const CustomModal: FC<IProps> = ({ show, children, handleClose }) => {
  return (
    <Modal show={show} centered onHide={handleClose}>
      {children}
    </Modal>
  );
};

export default CustomModal;
